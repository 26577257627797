import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown';
import { FaChevronDown } from 'react-icons/fa';

import { toggleHamburgerMenu } from 'src/actions/navigate';

class RepoDropdown extends Component {
  render () {
    let {
      repoName, isCollection, minimap
    } = this.props;

    const repoList = (isCollection) ? minimap.filter(config => config.key !== '/') : minimap;

    return (
      <Dropdown className="repo-selector">
        <DropdownTrigger>
          <span className="displayer">
            {(repoName === 'eos' ? "eosio" : repoName)}
          </span>
          <FaChevronDown className="caret-d" />
        </DropdownTrigger>
        {
          repoList && <DropdownContent>
            <ul>
              {
                repoList && repoList.map(config => {
                  let label = (config.repo === 'eos') ? "eosio" : config.repo;
                  return (<li key={config.repo}>
                    <Link className="repo-selector__item" onClick={() => this.props.toggleHamburgerMenu(false)} to={config.key}>
                      {label}
                    </Link>
                  </li>)
                })
              }
            </ul>
          </DropdownContent>
        }
      </Dropdown>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({toggleHamburgerMenu}, dispatch);

export default connect(null, mapDispatchToProps)(RepoDropdown);
