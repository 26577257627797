import React, { Component } from 'react';
import { Link } from 'gatsby';

import styled from 'styled-components';
import MEDIA_QUERIES from 'src/consts/media-queries';

const Navbar = styled.div`
  color: #111a44;
  line-height: 1.6;
  width: 100%;
  display: none;
  margin-bottom: 5px;
  margin-top: -15px;

  ${MEDIA_QUERIES.TABLET_ONLY} {
    display: block;
  }
  
  ${MEDIA_QUERIES.MOBILE} {
    display: block;
  }
`

const NavigationList = styled.ul`
  list-style: none;
  text-align: left;
  font-size: 15px;
  margin-left: 10px;
  font-weight: 700;
  margin-top: 50px;
`

const PrimaryNavigationLink = props => {
  let { path, text } = props.link;

  if (path !== "-1")
    return (path.startsWith('http')) ? 
      <a href={path}>{text}</a>
    : <Link to={path} activeStyle={{fontWeight:700, color: "#0F4FE3"}}>{text}</Link>
  else 
    return <button style={{cursor:'pointer'}} onClick={ev => window.history.go(-1)}>
      {text}
    </button>
}

class MobileNavigationBar extends Component {
  render () {
    let { primaryNavData } = this.props;

    return (
      <Navbar>
        <NavigationList>
        {
            (primaryNavData.length > 0) &&
            primaryNavData.map(link => 
              link.text === "NONE" ? null :
              <li key={link.path}>
                <PrimaryNavigationLink link={link}/>
              </li>)
          }
        </NavigationList>
      </Navbar>
    )
  }
}

export default MobileNavigationBar;
