
import actionTypes from 'src/consts/action-types';

const toggleHamburgerMenu = (forceValue) =>
  ({
    type: actionTypes.NAV_TOGGLE_HAMBURGER_MENU,
    forceValue,
  })

const setMenuScrollY = (offset) =>
({
  type: actionTypes.SET_MENU_SCROLL_Y,
  offset,
})

const storeNavDepth = (activeLink, children) =>
  ({
    type: actionTypes.STORE_NAV_DEPTH,
    activeLink,
    children
  });

export {
  toggleHamburgerMenu,
  setMenuScrollY,
  storeNavDepth
};
