import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown';
import { FaChevronDown } from 'react-icons/fa';

import styled from 'styled-components';
import MEDIA_QUERIES from 'src/consts/media-queries';

import { toggleHamburgerMenu } from 'src/actions/navigate';

const Chip = styled.span`
  color: ${props => props.textColor || '#000000'};
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  margin: 0 0.7rem;

  ${MEDIA_QUERIES.LAPTOP} {
    margin: 0 0.2rem;
  }
`

const constructChipTexts = tag => {
  let texts = [];
  /* NOT display chip (rc, beta, build) if the tag is older than latest*/
  if (tag.latest)
    texts.push({
      text: "Latest",
      txColor: "#0fa700",
      bgColor: "green"
    });
  if (tag.afterLatest) {
    if (tag.beta > 0)
    texts.push({
      text: "beta-"+tag.beta,
      txColor: "blue",
      bgColor: "blue"
    });
  if (tag.build > 0)
    texts.push({
      text: "build-"+tag.build,
      txColor: "blue",
      bgColor: "blue"
    })
  if (tag.rc > 0)
    texts.push({
      text: "RC"+tag.rc,
      txColor: "#a70000",
      bgColor: "red"
    })
  }
  if (tag.deprecated)
    texts.push({
      text: "Deprecated",
      txColor: "#7f7f7f",
      bgColor: "grey"
    })
  return texts;
}

class TagDropdown extends Component {
  render () {
    let {
      currentDDVersion, tagsList, isCollection, pathData, latestDDVersion
    } = this.props;
    let parsedTags = JSON.parse(tagsList);
    let texts = [];

    texts = constructChipTexts(
      parsedTags.filter(tag => currentDDVersion === tag.tag_reduced)[0]
    );

    return (
      <Dropdown className="version-checker">
        <DropdownTrigger>
          <span className="displayer">
            v{currentDDVersion}
            {
              (texts.length > 0) &&
              texts.map(chip => <Chip
                key={"header_"+currentDDVersion+"_"+chip.text}
                textColor={chip.txColor}
                chipColor={chip.bgColor}
              >
                {chip.text}
              </Chip>)
            }
          </span>
          <FaChevronDown className="caret-d"/>
        </DropdownTrigger>
        <DropdownContent>
          <ul>
            {
              parsedTags.map(tag => {
                let chipTexts = constructChipTexts(tag);
                let link = (isCollection) ?
                  pathData.collectionPath+"/"+tag.tag_reduced_friendly
                    : tag.tag_reduced_friendly;
                if (tag.latest) {
                  link = (isCollection) ?
                  pathData.collectionPath+"/"+latestDDVersion : latestDDVersion;
                }
                if (link[0] !== '/') link = "/"+link;
                return (<li key={tag.tag_reduced_friendly}>
                  <Link className="version-checker__item" onClick={()=>this.props.toggleHamburgerMenu(false)} to={link+"/index"}>
                    v{tag.tag_reduced}
                    {
                      (chipTexts.length > 0) &&
                      chipTexts.map(chip => <Chip
                        key={tag.tag_reduced_friendly+"_"+chip.text}
                        textColor={chip.txColor}
                        chipColor={chip.bgColor}
                      >
                        {chip.text}
                      </Chip>)
                    }
                  </Link>
                </li>)
              })
            }
          </ul>
        </DropdownContent>
      </Dropdown>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({toggleHamburgerMenu}, dispatch);

export default connect(null, mapDispatchToProps)(TagDropdown);
